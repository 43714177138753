import React from 'react';
import { useTranslation } from "react-i18next";

interface Contact {
  title: string,
  description: string
}

export default function Contact({title, description}: Contact) {
  const { t, i18n } = useTranslation();

  return ( 
    <section className="contact">
      <div className='grid'>
        <div className='grid__column grid__column--six'>
          <label>Contact</label>
          <h2>{title}</h2>
          <p>{description}</p>
          <a href="mailto:mail@esmeepeters.com" className="button button--light">{t('general-mail')}</a>
        </div>
      </div>
    </section>
  )
}
