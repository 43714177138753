import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { LangContext } from "../App";
import Header from "../sections/Header";
import SocialButtons from "../components/SocialButtons";

import Avatar from "../images/avatar-purple.jpg";
import Logo from "../images/logo.svg";

export default function Welcome() {
  const { t, i18n } = useTranslation();
  const { lang, setLang } = useContext(LangContext);
  const currentpage: string = 'welcome';

  return (
    <div className={currentpage}>
      <Header
        theme="light"
        currentpage={currentpage}
        avatar={Avatar}
      />
      <section className="welcome__container">
        <h1><img src={Logo} alt={t("name")} /></h1>
        <button className="button button--small" onClick={() => setLang(lang === "en" ? "nl" : "en")}>
          {t("welcome-changeLang")}
        </button>
        <SocialButtons />
        <div className="welcome__options">
          <h3>{t("welcome-about")}</h3>
          <Link className="button button--long" to="/">
            {t("welcome-website")}
          </Link>
          <h3>{t("welcome-services")}</h3>
          {/* <Link className="button button--long" to="/productdevelopment">
            {t("welcome-productdevelopment")}
          </Link> */}
          <Link className="button button--long" to="/productmanagement">
            {t("welcome-productmanagement")}
          </Link>
          <Link className="button button--long" to="/productdesign">
            {t("welcome-productdesign")}
          </Link>
         

          {/* <Link className="button button--long" to="/speaker" >
            {t('welcome-speaker')}
          </Link> */}
          {/* <h3>{t('Gratis')}</h3> 
          <Link className="button button--long" to="/" >
            {/* {t('welcome-website')} Bekijk mijn blog 
          </Link>
          <Link className="button button--long" to="/" >
            {/* {t('welcome-website')} Download mijn e-book
          </Link> */}
        </div>
      </section>
    </div>
  );
}
