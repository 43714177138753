import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FormSubscription from '../components/FormSubscription';

import ArrowIcon from "../images/icons/arrow-dropdown.svg";

interface Availability {
  currentpage: "homepage" | "freelance" | "product";
  variant: "message" | "extended";
  mailLists: any
}

export default function Availability({ currentpage, variant = "message", mailLists }: Availability) {
  const { t, i18n } = useTranslation();

  // Change to indicate if you are currently available for work based on services
  const [productAvailability, setProductAvailability] = useState("open");
  const [freelanceAvailability, setFreelanceAvailability] = useState("open");

  return (
    <div className={`availability availability--${currentpage}`}>
      {(currentpage === "homepage" && productAvailability === "open") && (
        <Link to="/productmanagement" className={`availability__alert availability--${productAvailability}`}>
          {t("availability-services-open")}<span>. {t("availability-services-readmore")}</span><img src={ArrowIcon} />
        </Link>
      )}

      {currentpage === "product" && (
        <div className={`availability__alert availability--${productAvailability}`}>
          {productAvailability === "open" ? t("availability-product-open") : t("availability-product-closed")}
        </div>
      )}

      {currentpage === "freelance" && (
        <div className={`availability__alert availability--${freelanceAvailability}`} >
          {freelanceAvailability === "open" ? t("availability-freelance-open") : t("availability-freelance-closed")}
        </div>
      )}

      {variant === "extended" && (
        <div className="availability__extended">
          {currentpage === "homepage" && (
            <div className={`availability__alert`}>
              <p>{t("availability-services-long")}</p>
              <FormSubscription mailLists={mailLists} />
            </div>
          )}
          {currentpage === "product" && (
            <div className={`availability__alert availability--${productAvailability}`}>
              {productAvailability === "open" ? t("availability-product-long-open") : t("availability-product-long-closed")}
            </div>
          )}
          {currentpage === "freelance" && (
            <div className={`availability__alert availability--${freelanceAvailability}`}>
              {freelanceAvailability === "open" ? (
                <>
                  {t("availability-freelance-long-open")}
                  <a href="https://calendly.com/esmee-peters/introduction" target="_blank" className="button button--light">{t('availability-freelance-cta')}</a>
                </>
              ) : (
                <>
                  <p>{t("availability-freelance-long-closed")}</p>
                  <FormSubscription mailLists={mailLists} />
                </>
              )}  
            </div>
          )}
        </div>
      )}
    </div>
  );
}
