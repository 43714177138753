import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ArrowIcon from "../images/icons/arrow-dropdown.svg";

export default function NavItems() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <li className="nav__items--hidden-plus">
        <Link to="/">{t("nav-about")}</Link>
      </li>
      <li className="nav__dropdown">
        <a>{t('nav-freelance')} <img src={ArrowIcon} /></a>
        {/* <Link to="/freelance">{t('nav-freelance')} <img src={ArrowIcon} /></Link> */}
        <ul className="nav__sublinks">
          <li>
            <Link to="/productmanagement">{t('nav-freelance-productmanagement')}</Link>
          </li>
          <li>
            <Link to="/productdesign">{t('nav-freelance-productdesign')}</Link>
          </li>
        </ul>
      </li>
      {/* <li>
        <Link to="/productdevelopment">{t("nav-product-productdevelopment")}</Link>
      </li> */}
      <li className="nav__items--hidden">
        <a href="mailto:mail@esmeepeters.com">Contact</a>
      </li>
    </>
  );
}
