import React, { useState} from 'react';
import { useTranslation } from "react-i18next";

import AccordionItem from './AccordionItem';

import LogoLoveren from '../images/logos/companies/loveren.jpg';
import LogoPlanon from '../images/logos/companies/planon.jpg';
import LogoSmartHealth from '../images/logos/companies/smarthealth.jpg';
import LogoOuwehand from '../images/logos/companies/ouwehand.jpg';

export default function Accordion() {
  const { t, i18n } = useTranslation();

  const accordion = [
    {
      logo: LogoLoveren,
      company: "MijnZorgPeiler",
      title: "Product Manager",
      date: t('accordion-date4'),
      description: "",
    },
    {
      logo: LogoPlanon,
      company: "Planon",
      title: "Product Owner",
      date: t('accordion-date3'),
      description: "",
    },
    {
      logo: LogoSmartHealth,
      company: "Smart Health",
      title: "Product Manager",
      date: t('accordion-date2'),
      description: "",
    },
    {
      logo: LogoOuwehand,
      company: t('accordion-company1'),
      title: "Product Manager",
      date: t('accordion-date1'),
      description: "",
    }
  ];

  return ( 
    <div className="accordion">
      <div className='accordion__label'>
        {t('accordion-label')}
      </div>
      {accordion.map(({ logo, company, title, date, description, index }) => (
        <AccordionItem index={index} logo={logo} company={company} title={title} date={date} description={description} />
      ))}

      {/* <button className="accordion__item" onClick={() => toggleAccordion}>
        <div className="accordion__header">
          <img className="accordion__image" src={LogoLoveren} alt="Planon" />
          <div className="accordion__info">
            <h3>Product Manager</h3>
            <div className="accordion__company">MijnZorgPeiler</div>
            <div className="accordion__time"></div>
            <div className="accordion__description">hallo</div>
          </div>
        </div>
      </button>
      <button className="accordion__item">
        <div className="accordion__header">
          <img className="accordion__image" src={LogoPlanon} alt="Planon" />
          <div className="accordion__info">
            <h3>Product Owner</h3>
            <div className="accordion__company">Planon</div>
            <div className="accordion__time">{t('accordion-date3')}</div>
            <div className="accordion__description">hallo</div>
          </div>
        </div>
      </button>
      <button className="accordion__item">
        <div className="accordion__header">
          <img className="accordion__image" src={LogoSmartHealth} alt="Smart Health" />
          <div className="accordion__info">
            <h3>Product Manager</h3>
            <div className="accordion__company">Smart Health</div>
            <div className="accordion__time">{t('accordion-date2')}</div>
            <div className="accordion__description">hallo</div>
          </div>  
        </div>
      </button> 
      <button className="accordion__item">
        <div className="accordion__header">
          <img className="accordion__image" src={LogoOuwhand} alt="Ouwehand" />
          <div className="accordion__info">
            <h3>Product Manager</h3>
            <div className="accordion__company">{t('accordion-company1')}</div>
            <div className="accordion__time">{t('accordion-date1')}</div>
            <div className="accordion__description">hallo</div>
          </div>      
        </div>
      </button> */}
      <div className="accordion__link">
        <a href="https://www.linkedin.com/in/esmeepeters/" target="_blank" arial-label="Go to LinkedIn" className="link link--large">
          {t('accordion-link')}
        </a>
      </div>
    </div>
  )
}
