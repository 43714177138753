import React from 'react';

import Socials1 from '../images/instagram/instagram_temp.jpg';

export default function Socials({currentpage}) {

  return ( 
    <section className="socials">
      <div className="grid">
        <div className="grid__column grid__column--four">
          <h2>Ik breid graag mijn kennis uit <span>– ik geloof dat continue ontwikkelen essentieel is voor succes.</span></h2>
        </div>
      </div>
      <div className="socials__container">
        {currentpage === 'homepage' && (
          <>
            <a className="socialspost">
              <div className="socialspost__number">01</div>
              <div className="substack-feed-widget">
                <a href="https://devmore.substack.com/p/samenwerken" target="_blank" className="flex w-full flex-wrap gap-4 flex-col p-2 rounded select-none box-border" rel="noopener noreferrer"><div className="w-full pb-[56.25%] bg-gray-200 bg-current bg-no-repeat rounded bg-center bg-cover" style={{ backgroundImage: "url(https://images.unsplash.com/photo-1434030216411-0b793f4b4173?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3wzMDAzMzh8MHwxfHNlYXJjaHwyfHx3b3JrJTIwYWdyZWVtZW50fGVufDB8fHx8MTcyNDA1Mzg3NXww&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080&quot" }}></div><div className="flex flex-col flex-1 gap-1 font-light"><div className="flex flex-col gap-1 leading-6 "><div className="text-lg font-bold sfw-title">Samenwerken met mij?</div></div><div className="flex flex-row flex-wrap items-center gap-1 text-xs leading-6 tracking-wider uppercase"><div className="flex flex-row items-center gap-1 flex-nowrap whitespace-nowrap"><div className="mr-1"></div></div></div></div></a>
                <a href=""></a>
                <a href=""></a>
              </div>
            </a>
            <a className="socialspost">
              <div className="socialspost__number">02</div>
              <div className="substack-feed-widget sfw-theme-custom sfw-layout-center">
                <a href="https://devmore.substack.com/about" target="_blank" className="flex  w-full flex-wrap gap-4 flex-col p-2 rounded select-none box-border" rel="noopener noreferrer"><div className="w-full pb-[56.25%] bg-gray-200 bg-current bg-no-repeat rounded bg-center bg-cover" style={{ backgroundImage: "url(https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F3b1252fa-f90e-4bab-84c4-0edb367e7f45_2075x2175.jpeg)" }}></div><div className="flex flex-col flex-1 gap-1 font-light"><div className="flex flex-col gap-1 leading-6 "><div className="text-lg font-bold sfw-title">Een korte introductie van Esmee Peters</div></div><div className="flex flex-row flex-wrap items-center gap-1 text-xs leading-6 tracking-wider uppercase"><div className="flex flex-row items-center gap-1 flex-nowrap whitespace-nowrap"><div className="mr-1"></div></div></div></div></a>
                <a href="https://devmore.substack.com/p/expertise" target="_blank" className="flex  w-full flex-wrap gap-4 flex-col p-2 rounded select-none box-border" rel="noopener noreferrer"><div className="w-full pb-[56.25%] bg-gray-200 bg-current bg-no-repeat rounded bg-center bg-cover" style={{ backgroundImage: "url(https://images.unsplash.com/photo-1521055654799-26999bb3a60c?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3wzMDAzMzh8MHwxfHNlYXJjaHwxfHxidW1weSUyMHJvYWR8ZW58MHx8fHwxNzIzOTEwMDAzfDA&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080&quot)" }}></div><div className="flex flex-col flex-1 gap-1 font-light"><div className="flex flex-col gap-1 leading-6 "><div className="text-lg font-bold sfw-title">Hoe ik tech-ondernemers help met mijn expertise…</div></div><div className="flex flex-row flex-wrap items-center gap-1 text-xs leading-6 tracking-wider uppercase"><div className="flex flex-row items-center gap-1 flex-nowrap whitespace-nowrap"><div className="mr-1"></div></div></div></div></a>
                <a href=""></a>
              </div>
            </a>
            <a className="socialspost">
              <div className="socialspost__number">03</div>
              <div className="substack-feed-widget sfw-theme-custom sfw-layout-center">
                <a href=""></a>
                <a href=""></a>
                <a href="https://devmore.substack.com/about" target="_blank" className="flex  w-full flex-wrap gap-4 flex-col p-2 rounded select-none box-border" rel="noopener noreferrer"><div className="w-full pb-[56.25%] bg-gray-200 bg-current bg-no-repeat rounded bg-center bg-cover" style={{ backgroundImage: "url(https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F3b1252fa-f90e-4bab-84c4-0edb367e7f45_2075x2175.jpeg)" }}></div><div className="flex flex-col flex-1 gap-1 font-light"><div className="flex flex-col gap-1 leading-6 "><div className="text-lg font-bold sfw-title">Een korte introductie van Esmee Peters</div></div><div className="flex flex-row flex-wrap items-center gap-1 text-xs leading-6 tracking-wider uppercase"><div className="flex flex-row items-center gap-1 flex-nowrap whitespace-nowrap"><div className="mr-1"></div></div></div></div></a>
              </div>
              <div className="socials__experience">
                <label>10+</label>
                <p>Door mijn jarenlange ervaring heb ik voldoende kennis opgebouwd om waardevolle inzichten te delen</p>
              </div>
            </a>
          </>
        )}
        {currentpage !== 'homepage' && (
          <>
            <a className="socialspost">
              <div className="socialspost__number">01</div>
              <div id="substack-feed-embed"></div>
            </a>
            <a className="socialspost">
              <div className="socialspost__number">02</div>
              <div id="substack-feed-embed"></div>
            </a>
            <a className="socialspost">
              <div className="socialspost__number">03</div>
              <div id="substack-feed-embed"></div>
              <div className="socials__experience">
                <label>10+</label>
                <p>Door mijn jarenlange ervaring heb ik voldoende kennis opgebouwd om waardevolle inzichten te delen</p>
              </div>
            </a>
          </>  
        )}
        <div className="socials__title">
          <p>Volg mijn via LinkedIn, Instagram en Substack</p>
        </div>
        <a href="https://www.instagram.com/esmee.a.peters/" target="_blank" className="socials__social-post">
          <img src={Socials1} />
        </a>
        <a href="https://www.instagram.com/esmee.a.peters/" target="_blank" className="socials__social-post">
          <img src={Socials1} />
        </a>
        <a href="https://www.instagram.com/esmee.a.peters/" target="_blank" className="socials__social-post">
          <img src={Socials1} />
        </a>
        <a href="https://www.instagram.com/esmee.a.peters/" target="_blank" className="socials__social-post">
          <img src={Socials1} />
        </a>
        <div className="socials__content">
         <p>Ik deel regelmatig tips, inzichten en marktontwikkelingen over product development en procesoptimalisatie. Via deze kanalen ontvang je praktische kennis waarmee je direct efficiënter kunt werken en meer waarde kunt creëren.</p>
        </div>
      </div>
      <a className="button button--light" target="_blank" href="https://www.linkedin.com/in/esmeepeters/">Connect met me op LinkedIn</a>
    </section>
  )
}