import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

import Header from '../sections/Header';
import Intro from '../sections/Intro';
import Services from '../sections/Services';
import Socials from '../sections/Socials';
import Contact from '../sections/Contact';

import Avatar from '../images/avatar-white.jpg';
import BackgroundImage from '../images/background/mobile.png';

export default function Homepage() {
  const { t, i18n } = useTranslation();
  const currentpage : string = 'homepage';

  return (
    <>
       <Helmet>
        <title>Esmee Peters</title>
        <meta name="description" content="Freelance Product Manager & Product Owner" />
        <meta name="keywords" content="Freelance, Product Manager, Product Owner, Product, Development, Digital Strategy, SaaS, tech" />
        <script src="https://substackapi.com/embeds/feed.js" async data-v="general"></script>
      </Helmet>
      <div className={currentpage}> 
        <Header 
          theme="cover"
          currentpage={currentpage}
          title={t('homepage-header-title')}
          subtitle={t('homepage-header-subtitle')}
          avatar={Avatar}
        />
        <Intro 
          currentpage={currentpage}
          leftColumn={BackgroundImage}
          rightColumnText={t('homepage-intro-text')}
          mailLists={{ NL: [4, 9], EN: [6, 8] }}
        />
        <Services 
          currentpage={currentpage}
          title={t('homepage-expertise-title')}
          description={t('homepage-expertise-text')}
        />
        <section className="context">
          <div className="grid">
            <div className="grid__column grid__column--four">
              <label>{t('homepage-context-label')}</label>
              <h2 className="context__title">{t('homepage-context-title')}</h2>
              <p className="context__description">{t('homepage-context-text')}</p>
              <a href="https://calendly.com/esmee-peters/introduction" target="_blank" className="button button--light">{t('homepage-context-cta')}</a>
            </div>
          </div>
        </section>
        
        {(t("general-currentLang") === 'NL') && (
          <Socials currentpage={currentpage} />
        )}
        <Contact 
          title={t('contact-title')}
          description={t('contact-text')}
        />
      </div>
    </>
  );
}
