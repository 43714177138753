import React from 'react';
import { useTranslation } from "react-i18next";

export default function Testimonials() {
  const { t, i18n } = useTranslation();

  return ( 
    <section className="testimonials">
      <div className="grid">
        <div className="grid__column grid__column--six">
          <div className="testimonial">
            {t("testimonial-1")}
          </div>
          <div className="testimonial">
          {t("testimonial-2")}
          </div>
          <div className="testimonial">
            {t("testimonial-3")}
          </div>
        </div>
      </div>
    </section>
  )
}