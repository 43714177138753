import React from 'react';
import { useTranslation } from "react-i18next";

import Availability from '../components/Availability';

interface Intro {
  currentpage: 'homepage' | 'freelance' | 'product',
  leftColumn: string,
  rightColumnTitle?: string,
  rightColumnText: string,
  mailLists: { NL: [], EN: []}
}

export default function Intro({ currentpage, leftColumn, rightColumnTitle, rightColumnText, mailLists }: Intro) {
  const { t, i18n } = useTranslation();

  return ( 
    <section className="intro">
      <div className="grid">
        <div className="grid__column grid__column--three">
          <img src={leftColumn} alt={t('intro-background-image')} />
        </div>
        <div className="grid__column grid__column--three">
          {rightColumnTitle && <h2>{rightColumnTitle}</h2> }
          <p>{rightColumnText}</p>
            <>
              <Availability 
                currentpage={currentpage}
                variant="extended"
                mailLists={mailLists}
              />
            </>
        </div>
      </div>
    </section>
  )
}
