import React, { useState } from 'react';

export default function AccordionItem({ index, logo, company, title, date, description }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <button key={index} className={`accordion__item ${isActive ? 'accordion__item--active' : ''}`} onClick={() => setIsActive(!isActive)}>
      <div className="accordion__header">
        <img className="accordion__image" src={logo} alt={company} />
        <div className="accordion__info">
          <h3>{title}</h3>
          <div className="accordion__company">{company}</div>
          <div className="accordion__time">{date}</div>
          {/* <div className="accordion__description">{description}</div> */}
        </div>
      </div>
    </button>
  )
}


