import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

import Header from '../sections/Header';
import Intro from '../sections/Intro';
import LogoOverview from '../sections/LogoOverview';
import Transformation from '../sections/Transformation';
import Testimonials from '../sections/Testimonials';
import Highlights from '../sections/Highlights';
import Socials from '../sections/Socials';
import Contact from '../sections/Contact';

import Avatar from '../images/avatar-white.jpg';
import BackgroundImage from '../images/background/mobile.png';

export default function ProductDevelopment() {
  const { t, i18n } = useTranslation();
  const currentpage : string = "product";

  return (
    <>
      <Helmet>
        <title>Product & Development Esmee Peters</title>
        <meta name="description" content="Product & Development" />
        <meta name="keywords" content="Product, Consultancy, Software, Development, Product Management, Agile" />
        <script src="https://substackapi.com/embeds/feed.js" async data-v="latest"></script>
      </Helmet>
      <div className={currentpage}>
        <Header 
          theme ="image"
          currentpage={currentpage}
          title={t("csl-header-title")}
          subtitle={t("csl-header-subtitle")}
          avatar={Avatar}
        />
        <Intro
          currentpage={currentpage}
          leftColumn={BackgroundImage}
          rightColumnTitle={t("csl-intro-title")}
          rightColumnText={t("csl-intro-text")}
          mailLists={{ NL: [4], EN: [6] }}
        />
        <Transformation />
        <Testimonials />
        {/* <LogoOverview
          currentpage={currentpage}
          title={t("csl-product-slider")}
          slider={true}
        /> */}

        {/* <Highlights /> */}
        
       
        {(t("general-currentLang") === 'NL') && (
          <Socials currentpage={currentpage} />
        )}
        <Contact 
          title={t('contact-title')}
          description={t('contact-text')}
        />
      </div>
    </>
  )
}