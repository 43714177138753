import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ExpertiseAreas from '../components/ExpertiseAreas';

interface Expertise {
  currentpage: string,
  title: string,
  description?: string,
  expertiseArea: string,
  expertise: 'product' | 'development';
}

export default function Expertise({ currentpage, title, description, expertiseArea, expertise }: Expertise) {
  const { t, i18n } = useTranslation();

  return ( 
    <section className="expertise">
      <div className="grid">
        <div className="grid__column grid__column--four">
          <h2 className="expertise__title">{title}</h2>
          {description && <p className="expertise__description">{description}</p>}
          {currentpage === 'freelance' && (
            <div className="expertise__skills">
              {expertiseArea}
            </div>
          )}
        </div>
      </div>
      <div className="expertise__areas">
        <div className="grid">
          <div className="grid__column grid__column--five">
            <ExpertiseAreas expertise={expertise} />
          </div>
        </div>
      </div>
      <div className="expertise__cta grid">
        <div className="grid__column grid__column--six">
        <a className="button button--light" href="mailto:mail@esmeepeters.com">
            {t('general-mail')}
          </a>
          <Link className="button button--dark" to="/">
            {t('general-about')}
          </Link>
        </div>
      </div>
    </section>
  )
}
