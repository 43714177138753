import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route, useLocation } from "react-router-dom";

import Homepage from "./pages/Homepage";
import Welcome from "./pages/Welcome";
// import Freelance from "./pages/Freelance";
import Productdesign from "./pages/Productdesign";
import Productmanagement from "./pages/Productmanagement";
import ProductDevelopment from "./pages/ProductDevelopment";
import PageNotFound from "./pages/PageNotFound";

export const LangContext = React.createContext(null);

export default function App() {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const [lang, setLang] = useState<string>("nl");

  useEffect(() => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(localStorage.getItem("lang"));
  }, [lang]);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return (
    <LangContext.Provider value={{ lang, setLang }}>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/welcome" element={<Welcome />} />
        {/* <Route path="/freelance" element={<Freelance />} /> */}
        <Route path="/productmanagement" element={<Productmanagement />} />
        <Route path="/productdesign" element={<Productdesign />} />
        <Route path="/productdevelopment" element={<ProductDevelopment />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </LangContext.Provider>
  );
}
