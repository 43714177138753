import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Transformation() {
  const { t, i18n } = useTranslation();

  return (
    <section className="transformation">
        <div className="grid">
          <div className="grid__column grid__column--four">
            <h2>{t("csl-transform-title")}</h2>
            <p>{t("csl-transform-text")}</p><br/>
            <h3>{t("cls-transform-results")}</h3>
            <ul>
              <li><b>{t('cls-transform-results-list.0.0')}</b>{t('cls-transform-results-list.1.1')}</li>
              <li><b>{t('cls-transform-results-list.2.2')}</b>{t('cls-transform-results-list.3.3')}</li>
              <li><b>{t('cls-transform-results-list.4.4')}</b>{t('cls-transform-results-list.5.5')}</li>
              <li><b>{t('cls-transform-results-list.6.6')}</b>{t('cls-transform-results-list.7.7')}</li>
              <li><b>{t('cls-transform-results-list.8.8')}</b>{t('cls-transform-results-list.9.9')}</li>
              <li><b>{t('cls-transform-results-list.10.10')}</b>{t('cls-transform-results-list.11.11')}</li>
              <li><b>{t('cls-transform-results-list.12.12')}</b>{t('cls-transform-results-list.13.13')}</li>
            </ul>
          </div>   
          <div className="grid__column grid__column--two">
          </div>
        </div>
    </section>
  );
}
